import { ToastProgrammatic } from 'buefy'
import { format, formatISO9075, formatRelative } from 'date-fns'
import locale from 'date-fns/locale/en-AU'

export const toString = (value: string) => value.toLowerCase().replace('_', ' ')

/**
 *
 * @param value 'string | number | Date | null | undefined' value
 * @returns string value -  "day Month year (30 Apr 24)"
 */
export const toCompactDate = (value: string | number | Date | null | undefined) =>
  value ? format(new Date(value), 'd MMM yy') : ''

/**
 *
 * @param value 'string | number | Date | null | undefined' value
 * @returns string value -  "day Month year (30 Apr 2024)"
 */
export const toShortDate = (value: string | number | Date | null | undefined) =>
  value ? format(new Date(value), 'd MMM yyyy') : ''

/**
 *
 * @param value 'string | number | Date | null | undefined' value
 * @returns string value -  "Day of the week, Month day year (Monday, April 30th 2024)"
 */
export const toLongDate = (value: string | number | Date | null | undefined) =>
  value ? format(new Date(value), 'PPPP') : ''

/**
 *
 * @param value 'string | number | Date' value
 * @returns string value -  "Day/Month/Year (30/04/2024)"
 */
export const toRelativeDate = (value: string | number | Date) => formatRelative(new Date(value), new Date(), { locale })

/**
 *
 * @param value 'string | number | Date' value
 * @returns string value -  "Day-Month-Year (30-04-2024)"
 */
export const toISO8601Date = (value: string | number | Date) =>
  formatISO9075(new Date(value), { representation: 'date' })

export const truncate = (value: string, length: number = 40) =>
  value && value.length > length ? value.substr(0, length) + '...' : value

export const toReadableTimestamp = (value: string | number | Date | null | undefined) =>
  value ? format(new Date(value), 'd MMM, yyyy h:mm:ss a') : ''

export const toCamelCaseFromProperCase = (value: string) => value[0].toLowerCase() + value.substr(1)

export const toSentenceFromProperCase = (value: string) => value.split(/(?=[A-Z])/).join(' ')

/**
 * check the param type if it's a string convert to float else return the number
 * @param value string|number value
 * @returns string value -  "float" | "number"
 */
export const toFloat = (value: string | number) => {
  return typeof value === 'string' ? parseFloat(value) : value
}

/**
 *
 * @param value boolean value
 * @returns string value -  "Yes" | "No" | "Not Specified"
 */
export const getDisplayTextForBoolean = (value: boolean) => {
  return value === true ? 'Yes' : value === false ? 'No' : 'Not Specified'
}

/**
 *
 * @param value boolean value
 * @returns string value -  "Required" | "Not Required"
 */
export const getRequiredDisplayTextForBoolean = (value?: boolean | null) => {
  return value ? 'Required' : 'Not Required'
}

/**
 * get a string value check for "empty string"|null|undefined if true returns "Not Specified"
 * else return the string value
 * @param value string value
 * @returns string value -  "String Value" | "Not Specified"
 */
export const getDisplayTextForString = (value?: string | null): string => {
  return value ? value : 'Not Specified'
}

/**
 * get a string value check for "empty string"|null|undefined if true returns ""(Empty String)
 * else return the string value
 * @param value string value
 * @returns string value -  "String Value" | ""
 */
export const getDisplayStringValueForTable = (value?: string | null) => {
  return value ? value : ''
}

/**
 * get a string value
 * return the string value without spaces
 * @param value string value
 * @returns string value -  "String Value without spaces"
 */
export const removeWhiteSpace = (value: string) => {
  return value.replace(/ /g, '')
}

/**
 * get a string value
 * return the string value with the first letter capitalized in each word
 * @param value string value
 * @returns string value -  "String value with the first letter capitalized in each word"
 */
export const capitalizeFirstLetter = (value: string) => {
  return value.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
}

/**
 * get a string value and save to clipboard
 * @param value string value
 * @param message optional string value
 */
export const copyToClipboard = (value: string, message: string = 'Successfully copied to clipboard') => {
  if (!value) {
    ToastProgrammatic.open({
      message: 'The content is empty',
      type: 'is-warning'
    })
    return
  }
  navigator.clipboard.writeText(value)
  ToastProgrammatic.open({
    message: message,
    type: 'is-success'
  })
}

/**
 * get a string value and return formatted guid
 * @param value string value
 */
export const formatGuidWithDashes = (guid: string | null): string | null => {
  if (!guid) return guid
  // Check if the GUID already contains dashes using a regex pattern
  const dashGuidPattern = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/

  if (dashGuidPattern.test(guid)) {
    return guid // Already formatted with dashes
  } else {
    // Remove any non-alphanumeric characters and format it as a GUID
    const cleanGuid = guid.replace(/[^a-fA-F0-9]/g, '')
    return `${cleanGuid.slice(0, 8)}-${cleanGuid.slice(8, 12)}-${cleanGuid.slice(12, 16)}-${cleanGuid.slice(
      16,
      20
    )}-${cleanGuid.slice(20)}`
  }
}
